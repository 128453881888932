
import { defineComponent } from 'vue'
import { QBtn, QFooter, QSeparator, QToolbar } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import { refStore } from 'stores/__common'
import ContainerTemplate from 'components/template/container-template.vue'
import IconImage from 'src/components/simple/icon-image.vue'
import ToolbarTitle from 'components/simple/page/toolbar-title.vue'
import QeeplRating from 'components/advanced/qeepl-rating.vue'
import useUserDetails from 'stores/userDetails'
import { INSTAGRAM_URL, TELEGRAM_URL } from 'src/model/constants'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'

const name = 'page-footer'

const pages = [
  'blog',
  'reviews',
  'contact',
  'partners'
]

const conditionPages = [
  'terms',
  'policy'
]

const matInstagram = 'M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z'
const matTelegram = 'M9.78,18.65L10.06,14.42L17.74,7.5C18.08,7.19 17.67,7.04 17.22,7.31L7.74,13.3L3.64,12C2.76,11.75 2.75,11.14 3.84,10.7L19.81,4.54C20.54,4.21 21.24,4.72 20.96,5.84L18.24,18.65C18.05,19.56 17.5,19.78 16.74,19.36L12.6,16.3L10.61,18.23C10.38,18.46 10.19,18.65 9.78,18.65Z'

export default defineComponent({
  name,
  props: {},
  components: {
    QFooter,
    QToolbar,
    QBtn,
    QSeparator,
    ContainerTemplate,
    ToolbarTitle,
    IconImage,
    QeeplRating,
  },
  setup() {
    const { isUser, isPartner } = refStore(useUserDetails())
    const { isWorldContext } = useUserPreviewExtension()
    const { tp, t } = useI18n$('global.pages')
    const { toPartners, toPage, toRoot } = useRoutes()

    return {
      matInstagram,
      matTelegram,
      INSTAGRAM_URL,
      TELEGRAM_URL,
      isWorldContext,
      isUser,
      isPartner,
      anonymousPages: pages,
      conditionPages,
      toPartners,
      toPage,
      toRoot,
      tp,
      t
    }
  }
})
