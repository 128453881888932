
import { computed, defineComponent, onMounted, ref } from 'vue'
import { QSeparator } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import useFirebase, { FirebaseAuthType, localStorageAuthTypePramName } from 'src/compositions/firebase'
import AuthBtn from 'components/simple/auth-btn.vue'
import useAnonymousApi from 'src/api/anonymous.api'
import useAsync from 'src/compositions/async'
import useAuthorization from 'src/compositions/authorization'
import useAnalytics from 'src/compositions/analytics'

const name = 'firebase-auth'

export default defineComponent({
  name,
  components: {
    AuthBtn,
    QSeparator
  },
  setup(props, { emit }) {
    const { authorizeByFirebase } = useAnonymousApi()
    const { tp, language } = useI18n$(name)
    const { authenticate, initialize, finishAuthenticate, firebaseReady } = useFirebase()
    const { handleJwtResponse } = useAuthorization()
    const { logEvent } = useAnalytics()

    const type = ref<FirebaseAuthType>(localStorage.getItem(localStorageAuthTypePramName) as FirebaseAuthType || 'GOOGLE')

    onMounted(() => {
      initialize()
        .then(finishAuthenticate)
        .then(handleToken)
    })

    const { run: startAuthentication, running: authenticating } = useAsync(
      () => authenticate(type.value),
      (token) => handleToken(token),
      (error) => {
        console.error(error)
      }
    )

    const { run: handleToken, running: handlingToken } = useAsync(
      async (token: any) => {
        if (typeof token === 'string' && token) {
          const jwtResponse = await authorizeByFirebase({ idToken: token, language: language.value }, '')
          return handleJwtResponse(jwtResponse, true)
            .then(() => {
              logEvent({
                name: 'sign_up',
                params: {
                  method: 'Email'
                }
              })
            })
        }
      }
    )

    const processing = computed(() => authenticating.value || handlingToken.value)

    const doAuthenticate = async (newType: FirebaseAuthType) => {
      type.value = newType
      await startAuthentication()
    }

    return {
      firebaseReady,
      type,
      processing,
      authenticate: doAuthenticate,
      tp,
    }
  }
})
