import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center no-wrap" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = { class: "col-auto q-pl-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    disable: _ctx.disable,
    i18n: _ctx.$options.name,
    loading: _ctx.loading,
    "text-color": _ctx.textColor,
    class: _normalizeClass(["auth-btn text-transform-none full-width", `auth-btn__${ _ctx.authType.toLowerCase() }`]),
    rounded: "",
    flat: "",
    padding: "sm",
    onClick: _ctx.click
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_icon, {
            alt: _ctx.alt,
            name: `img:/images/auth/${ _ctx.authType.toLowerCase() }.svg`,
            size: "1.2rem"
          }, null, 8, ["alt", "name"])
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
      ])
    ]),
    _: 1
  }, 8, ["disable", "i18n", "loading", "text-color", "class", "onClick"]))
}