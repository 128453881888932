import { RouteRecordRaw } from 'vue-router'
import { Pinia } from 'pinia'
import { QSsrContext } from '@quasar/app-webpack'
import useServerRender from 'stores/serverRender'
import Routing from 'pages/routing.vue'
import {
  COMPANIES_MAP,
  COMPANIES_MAP__OPEN,
  COMPANIES_MAP__OPEN__BOOK,
  COMPANIES_MAP__OPEN__BOOK__PAY,
  CONTACT,
  ERROR_404,
  LOGIN,
  MY_ORDERS__OPEN,
  MY_ORDERS__OPEN__EDIT,
  MY_ORDERS__OPEN__INSTRUCTION,
  PAYMENT__SUCCESS,
  POLICY,
  PROFILE,
  TERMS,
} from 'pages/names'
import { ALLOWED_LANGUAGES, ROLE_USER } from 'src/model/constants'
import { Language } from 'src/model/language.model'
import { Error404 } from 'src/model/common.model'

const routes: (additional: RouteRecordRaw[], store: Pinia, ssrContext?: QSsrContext | null) => RouteRecordRaw[] = (additional, store, ssrContext) => {
  const serverRender = useServerRender(store)
  const result = [
    { path: '/', redirect: '/en' },
    {
      path: '/error404',
      name: ERROR_404,
      component: () => import(/* webpackChunkName: "error404" */ 'pages/error404.vue')
    },
    {
      path: '/:language',
      beforeEnter: (to: any, from: any, next: any) => {
        if (ALLOWED_LANGUAGES.includes(to.params.language as Language) && serverRender.code === 200) {
          next()
        } else {
          if (ssrContext) {
            throw new Error404()
          } else {
            next({ name: ERROR_404 })
          }
        }
      },
      component: Routing,
      children: [
        {
          path: 'terms',
          name: TERMS,
          component: () => import(/* webpackChunkName: "terms", webpackMode: "eager" */ 'pages/anonymous/terms.vue')
        },
        {
          path: 'policy',
          name: POLICY,
          component: () => import(/* webpackChunkName: "policy", webpackMode: "eager" */ 'pages/anonymous/policy.vue')
        },
        {
          path: 'profile',
          name: PROFILE,
          component: () => import(/* webpackChunkName: "profile" */ 'pages/user/profile.vue'),
          meta: { authorities: 'ANY' }
        },
        {
          path: 'login',
          name: LOGIN,
          component: () => import(/* webpackChunkName: "login", webpackMode: "eager" */ 'pages/anonymous/login.vue')
        },
        {
          path: 'contact',
          name: CONTACT,
          component: () => import(/* webpackChunkName: "contact", webpackMode: "eager" */ 'pages/anonymous/contact.vue')
        },
        {
          path: 'map',
          component: () => import(/* webpackChunkName: "companies-map", webpackMode: "eager" */ 'pages/anonymous/companies-map.vue'),
          children: [{
            path: '',
            name: COMPANIES_MAP,
            component: () => import(/* webpackChunkName: "companies-map-open" */ 'pages/anonymous/companies-map/list/companies-map__list.vue'),
          }, {
            path: ':companyId',
            name: COMPANIES_MAP__OPEN,
            component: () => import(/* webpackChunkName: "companies-map-open" */ 'pages/anonymous/companies-map/open/companies-map__open.vue'),
            children: [{
              path: 'book',
              name: COMPANIES_MAP__OPEN__BOOK,
              component: () => import(/* webpackChunkName: "companies-map-open" */ 'pages/anonymous/companies-map/open/book/companies-map__open__book.vue'),
              children: [{
                path: 'pay',
                name: COMPANIES_MAP__OPEN__BOOK__PAY,
                components: {
                  yookassa: () => import(/* webpackChunkName: "companies-map-open" */ 'pages/anonymous/companies-map/open/book/pay/companies-map__open__book__pay--yookassa.vue'),
                  stripe: () => import(/* webpackChunkName: "companies-map-open" */ 'pages/anonymous/companies-map/open/book/pay/companies-map__open__book__pay--stripe.vue')
                },
                meta: { authorities: [ROLE_USER], loginRedirect: false }
              }]
            }]
          },]
        },
        {
          path: 'orders',
          name: 'my-orders',
          component: () => import(/* webpackChunkName: "my-orders" */ 'pages/user/my-orders.vue'),
          children: [{
            path: ':orderId',
            name: MY_ORDERS__OPEN,
            component: () => import(/* webpackChunkName: "my-orders" */ 'pages/user/my-orders/my-orders__open.vue'),
            children: [{
              path: 'instruction',
              name: MY_ORDERS__OPEN__INSTRUCTION,
              component: () => import(/* webpackChunkName: "my-orders" */ 'pages/user/my-orders/open/my-orders__open__instruction.vue'),
              meta: { authorities: [ROLE_USER] }
            }, {
              path: 'edit',
              name: MY_ORDERS__OPEN__EDIT,
              component: () => import(/* webpackChunkName: "my-orders" */ 'pages/user/my-orders/open/my-orders__open__edit.vue'),
              meta: { authorities: [ROLE_USER] }
            },],
            meta: { authorities: [ROLE_USER] }
          }],
          meta: { authorities: [ROLE_USER] }
        },
        {
          path: 'payment/success',
          name: PAYMENT__SUCCESS,
          component: () => import(/* webpackChunkName: "payment__success", webpackMode: "eager" */ 'pages/anonymous/payment__success.vue')
        },
        ...additional,
      ]
    }
  ]
  if (!ssrContext) {
    result.push({
      path: '/:catchAll(.*)*',
      redirect: '/error404'
    })
  }
  return result
}

export default routes
