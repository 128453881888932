import { ChargeType, DateRange, DateTime, ItemsMetadata, Range, TimeRange } from 'src/model/common.model'
import {
  addDays,
  addHours,
  addMonths,
  buildDate,
  buildDateTimeRange,
  DEFAULT_BETWEEN_OPTS,
  destructDateRange,
  formatDate,
  isBetweenDates,
  isSameDay,
  isToday,
  maxDate,
  MIDNIGHT,
  nextInterval,
  now,
  parseDate,
  parseTime
} from 'src/functions/date'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { buildDateWorkRange, findNextWorkingDate, getWorkRange } from 'src/functions/company'
import { inject, provide, ref, Ref } from 'vue'
import { OrderLength } from 'src/model/order.model'

export const initDefaultDateRange = (timezone?: string): DateRange => {
  const nowTime = formatDate(now(timezone))
  return {
    from: nowTime,
    to: nowTime
  }
}

export const checkDateTime = (dateRange: DateRange, timeRange: TimeRange, timezone?: string) => {
  const fromDate = buildDate(dateRange.from, timeRange.from)
  const toDate = buildDate(dateRange.to, timeRange.to)
  const nowTime = now(timezone)

  return fromDate.getTime() >= nowTime.getTime() && toDate.getTime() > fromDate.getTime()
}

export const checkDateTimeForCompany = (
  company: AnonymousCompanyProjection,
  dateRange: DateRange,
  timeRange: TimeRange,
  holidays: string[] = []
) => {
  const nextWorkingDayFrom = findNextWorkingDate(company, holidays, dateRange.from) || formatDate(now(company.timezone))
  const nextWorkingDayTo = findNextWorkingDate(company, holidays, dateRange.to) || formatDate(now(company.timezone))
  const nextDateRange = { from: nextWorkingDayFrom, to: nextWorkingDayTo }

  const workRangeFrom = buildDateWorkRange(company, nextDateRange.from)
  const workRangeTo = buildDateWorkRange(company, nextDateRange.to, true)

  const { from: fromDate, to: toDate } = buildDateTimeRange(dateRange, timeRange, false)
  const nowTime = now(company.timezone)

  return dateRange.from === nextWorkingDayFrom && dateRange.to === nextWorkingDayTo
    && isBetweenDates(fromDate, workRangeFrom.from, workRangeFrom.to, DEFAULT_BETWEEN_OPTS)
    && isBetweenDates(toDate, workRangeTo.from, workRangeTo.to, DEFAULT_BETWEEN_OPTS)
    && fromDate.getTime() >= nowTime.getTime()
    && toDate.getTime() > fromDate.getTime()
}

export const proposeDefaultFromDate = (date: string, time: string, timezone?: string): Date => {
  let nextWorkingDay = date
  const fromDate = parseDate(nextWorkingDay)
  const fromTime = parseTime(time)
  fromDate.setHours(fromTime.getHours(), fromTime.getMinutes(), fromTime.getSeconds(), fromTime.getMilliseconds())
  const currentDate = now(timezone)
  const startDates = []
  if (currentDate.getTime() > fromDate.getTime()) {
    nextWorkingDay = formatDate(currentDate)
  }
  if (isToday(nextWorkingDay, timezone)) {
    let next = nextInterval(currentDate)
    if (!isSameDay(next, addHours(next, 1))) {
      next = addDays(next, 1)
      next.setHours(0, 0, 0, 0)
    }
    startDates.push(next)
  }
  return maxDate(fromDate, ...startDates)
}

export const proposeFromDate = (
  company: AnonymousCompanyProjection,
  dateTime: DateTime,
  holidays: string[] = []
): Date => {
  const nextWorkingDay = findNextWorkingDate(company, holidays, dateTime.date) || formatDate(now(company.timezone))
  const workRange = buildDateWorkRange(company, nextWorkingDay)
  const startDates = []
  if (isToday(nextWorkingDay, company.timezone)) {
    startDates.push(nextInterval(now(company.timezone)))
  }
  let result = maxDate(workRange.from, ...startDates)
  const default_ = company.default

  if (!default_?.lunchTime) {
    return result
  } else {
    const resultStr = formatDate(result)
    const lunchRange = buildDateTimeRange({ from: resultStr, to: resultStr }, default_.lunchTime)
    return isBetweenDates(result, lunchRange.from, lunchRange.to) ? lunchRange.to : result
  }
}

export const proposeDefaultDateTimeRange = (
  dateRange: DateRange | null,
  timeRange: TimeRange | null,
  timezone?: string,
  holidays: string[] = []
): Range<DateTime> => {
  dateRange = dateRange || initDefaultDateRange(timezone)
  timeRange = timeRange || { from: MIDNIGHT, to: MIDNIGHT }
  let from = proposeDefaultFromDate(dateRange.from, timeRange.from, timezone)
  let to = parseDate(dateRange.to)

  if (!isSameDay(from, to) && from.getTime() > to.getTime()) {
    to = parseDate(formatDate(from))
  } else {
    const fromTime = parseTime(timeRange.to)
    to.setHours(fromTime.getHours(), fromTime.getMinutes(), fromTime.getSeconds(), fromTime.getMilliseconds())
  }
  if (to.getHours() === 0) {
    to = addDays(to, 1)
  }

  return destructDateRange({ from, to })
}

export const proposeDateTimeRange = (
  company: AnonymousCompanyProjection,
  dateRange: DateRange,
  timeRange: TimeRange,
  holidays: string[] = []
): Range<DateTime> => {
  const from = proposeFromDate(company, { date: dateRange.from, time: timeRange.from }, holidays)

  const nextWorkingDayTo = findNextWorkingDate(company,
    holidays,
    formatDate(maxDate(from, parseDate(dateRange.to)))) || formatDate(now(company.timezone))
  const workRange = buildDateWorkRange(company, nextWorkingDayTo)

  return destructDateRange({ from, to: workRange.to })
}

export const convertToChargeType = (
  newChargeType: ChargeType,
  dateRange: DateRange,
  timeRange: TimeRange,
  companies: AnonymousCompanyProjection[]
): { dateRange: DateRange, timeRange: TimeRange } => {
  const findWorkDateTimes = (dateFrom: number | string | Date, dateTo: number | string | Date) => {
    const workRangesFrom = companies.map(c => getWorkRange(c, dateFrom).from).filter(c => c !== MIDNIGHT)
    const workRangesTo = companies.map(c => getWorkRange(c, dateTo).to).filter(c => c !== MIDNIGHT)
    const minWorkRange = workRangesFrom.sort()[workRangesFrom.length - 1] || MIDNIGHT
    const maxWorkRange = workRangesTo.sort()[0] || MIDNIGHT
    return {
      from: minWorkRange,
      to: maxWorkRange
    }
  }
  const { from: fromDate, to: toDate } = buildDateTimeRange(dateRange, timeRange, true)
  switch (newChargeType) {
    case 'HOURLY': {
      const resultToDate = addDays(fromDate, 1).getTime() > toDate.getTime() ? addHours(fromDate, 1) : toDate
      const workDateTimes = findWorkDateTimes(fromDate, resultToDate)
      return {
        dateRange: { from: formatDate(fromDate), to: formatDate(resultToDate) },
        timeRange: workDateTimes
      }
    }
    case 'MONTHLY': {
      const resultToDate = addMonths(fromDate, 1).getTime() > toDate.getTime() ? addMonths(fromDate, 1) : toDate
      const workDateTimes = findWorkDateTimes(fromDate, resultToDate)
      return {
        dateRange: { from: formatDate(fromDate), to: formatDate(resultToDate) },
        timeRange: workDateTimes
      }
    }
  }
  return { dateRange, timeRange }
}

export const provideUserOrder = (
  dateRange: Ref<DateRange>,
  timeRange: Ref<TimeRange>,
  itemsMetadata: Ref<ItemsMetadata>,
  orderLength: Ref<OrderLength> = ref('SEVERAL_MONTHS'),
  company: Ref<AnonymousCompanyProjection | null>,
  hourly: Ref<boolean> = ref(false),
  chargeType: Ref<ChargeType> = ref('DAILY'),
  setDateTime: (newDateRange: DateRange, newTimeRange: TimeRange | null, forceDatTime: boolean) => void,
  setItemsMetadata: (setItemsMetadata: ItemsMetadata) => void,
  setOrderLength: (newOrderLength: OrderLength) => void,
  setHourly: (newHourly: boolean) => void,
  setChargeType: (newChargeType: ChargeType) => void,
) => {
  provide('dateRange', dateRange)
  provide('timeRange', timeRange)
  provide('itemsMetadata', itemsMetadata)
  provide('orderLength', orderLength)
  provide('company', company)
  provide('hourly', hourly)
  provide('chargeType', chargeType)
  provide('setDateTime', setDateTime)
  provide('setItemsMetadata', setItemsMetadata)
  provide('setOrderLength', setOrderLength)
  provide('setHourly', setHourly)
  provide('setChargeType', setChargeType)
}

export const injectUserOrder = () => {
  return {
    dateRange: inject('dateRange') as Ref<DateRange>,
    timeRange: inject('timeRange') as Ref<TimeRange>,
    itemsMetadata: inject('itemsMetadata') as Ref<ItemsMetadata>,
    orderLength: inject('orderLength') as Ref<OrderLength>,
    company: inject('company') as Ref<AnonymousCompanyProjection | null>,
    chargeType: inject('chargeType') as Ref<ChargeType>,
    hourly: inject('hourly') as Ref<boolean>,
    setDateTime: inject('setDateTime') as (newDateRange: DateRange, newTimeRange: TimeRange | null, forceDatTime: boolean) => void,
    setItemsMetadata: inject('setItemsMetadata') as (setItemsMetadata: ItemsMetadata) => void,
    setOrderLength: inject('setOrderLength') as (newOrderLength: OrderLength) => void,
    setHourly: inject('setHourly') as (newHourly: boolean) => void,
    setChargeType: inject('setChargeType') as (newChargeType: ChargeType) => void
  }
}
