import { InjectionKey, } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { inject } from 'src/compositions/common'
import useEnvironment from 'src/compositions/environment'
import { Amount } from 'src/model/common.model'
import useAnonymousApi from 'src/api/anonymous.api'
import { CITY_ADS_CLICK_ID_STORE_NAME } from 'src/model/constants'

const cityAdsKey: InjectionKey<ReturnType<typeof useCityAds_>> = Symbol('qeepl_cityAds')

export interface CityAdsEvent {
  orderId: string
  price: Amount
  status: 'new' | 'done' | 'cancel'
}

function useCityAds_() {
  const { postCityAdsEvent } = useAnonymousApi()
  const { localStorage } = useQuasar()
  const { currentRoute } = useRouter()
  const { csr, prod, app } = useEnvironment()

  const tryInit = () => {
    const query = currentRoute.value?.query || {}
    if ('click_id' in query && 'utm_source' in query && query.utm_source === 'cityads') {
      localStorage.set(CITY_ADS_CLICK_ID_STORE_NAME, query.click_id as string)
    }
  }

  const tryCleanup = () => {
    localStorage.remove(CITY_ADS_CLICK_ID_STORE_NAME)
  }

  const logEvent = (event: CityAdsEvent | (() => CityAdsEvent)) => {
    try {
      if (csr && prod && !app) {
        event = typeof event === 'function' ? event() : event
        const clickId = localStorage.getItem(CITY_ADS_CLICK_ID_STORE_NAME) as string
        clickId && postCityAdsEvent({ ...event, clickId })
      } else {
        console.info('City ads tracking is disabled, was a try to send: ', event)
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  return {
    tryInit,
    tryCleanup,
    logEvent
  }
}

export default function useCityAds() {
  return inject(cityAdsKey, () => useCityAds_())
}
