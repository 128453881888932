import { App } from 'vue'
import { boot } from 'quasar/wrappers'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { SITE_URL } from 'src/model/constants'
import { Notify } from 'quasar'
import { Router } from 'vue-router'
import { Pinia } from 'pinia'
import { Container } from 'src/model/common.model'
import useUserDetails from 'stores/userDetails'
import { matErrorOutline, matRefresh } from 'src/config/icons'

declare module 'pinia' {
  export interface Pinia {
    api: Api
  }
}

export type AxiosCallback<D> = ((res: AxiosResponse<D>) => void)

declare module 'axios' {
  export interface AxiosRequestConfig<D = any> {
    callback?: AxiosCallback<D>
  }
}

const addAuthorizationHeaders = (store: Pinia) => {
  return (config: AxiosRequestConfig) => {
    const { token } = useUserDetails(store)
    if (token && !config.url?.startsWith('/_self')) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
}

const logRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // @ts-ignore
  config.meta = config.meta || {}
  // @ts-ignore
  config.meta.requestStartedAt = new Date().getTime()
  return config
}

const logResponseSuccess = (res: AxiosResponse): AxiosResponse => {
  if (res?.config) {
    // @ts-ignore
    console.log(`Execution time for: ${res.config.url} - ${new Date().getTime() - res.config.meta.requestStartedAt} ms`)
  } else {
    console.log(`Execution time is unknown, res: `, res)
  }
  return res
}

const logResponseRejected = (error: any) => {
  if (error?.config) {
    console.error(`ERROR: Execution time for: ${error.config.url} - ${new Date().getTime() - error.config.meta.requestStartedAt} ms`)
  } else {
    console.error(`ERROR: Execution time is unknown, error: `, error)
  }
  throw error
}

const setupServerAxiosInterceptors = (axios: AxiosInstance) => {
  axios.interceptors.request.use(logRequest)
  axios.interceptors.response.use(logResponseSuccess, logResponseRejected)
}

const setupAxiosInterceptors = (axios: AxiosInstance, app: App, router: Router, urlPath: string, store: Pinia) => {
  const errorConfigs: Container<AxiosRequestConfig> = {}
  const onResponseError = (err: AxiosError) => {
    console.error(`Error occurred: ${JSON.stringify(err)}`)
    const status = err.status || err.response?.status || 500
    if (status === 403 || status === 401) {
      return router.push(router.resolve({
        name: 'login',
        query: { returnURL: urlPath }
      }))
    }
    if (status === 406 || status === 429) {
      const { markUserBlocked } = useUserDetails(store)
      markUserBlocked()
    }
    if (status >= 500) {
      const $t = app.config.globalProperties.$t
      const id = `${err.config.method} ${err.config.url}`
      if (!errorConfigs[id]) {
        Notify.create({
          type: 'negative',
          message: $t('unhandled.title'),
          caption: $t('unhandled.description'),
          position: 'top',
          timeout: 10000,
          progress: true,
          icon: matErrorOutline,
          actions: [{
            icon: matRefresh,
            flat: true,
            color: 'white',
            size: 'lg',
            padding: 'xs',
            handler: () => {
              try {
                Object.values(errorConfigs)
                  .filter(c => !!c.callback)
                  .forEach(c => {
                    axios.request(c)
                      .then(res => c.callback!(res))
                  })
              } finally {
                Object.keys(errorConfigs).forEach(k => delete errorConfigs[k])
              }
            }
          }],
          onDismiss: () => {
            Object.keys(errorConfigs).forEach(k => delete errorConfigs[k])
          }
        })
      }
      errorConfigs[id] = err.config
    }
    return Promise.reject(err)
  }
  if (axios.interceptors) {
    axios.interceptors.request.use(addAuthorizationHeaders(store))
    axios.interceptors.response.use(res => res, onResponseError)
  }
}

export default boot(({
  app,
  router,
  urlPath,
  ssrContext,
  store
}) => {
  const self = axios.create({ baseURL: SITE_URL })
  store.api = { self }
  if (ssrContext) {
    setupServerAxiosInterceptors(self)
  }
  setupAxiosInterceptors(self, app, router, urlPath, store)
})

export interface Api {
  self: AxiosInstance
}

export function useApi (): Api {
  return {
    self: axios.create({ baseURL: SITE_URL })
  }
}
