import { UserOrderProjection } from 'src/model/order.model'
import { Language } from 'src/model/language.model'
import { CreateOrderRequest, EditOrderRequest, GetEditPriceRequest, UpdateOrderRequest } from 'src/api/dto/order'
import { CompletePaymentRequest } from 'src/api/dto/payment'
import { UserProjection } from 'src/model/user.model'
import { UpdateUserRequest } from 'src/api/dto/user'
import { OrderReviewRequest } from 'src/api/dto/review'
import { InjectionKey } from 'vue'
import { getActivePinia, Pinia } from 'pinia'
import { injectSafe } from 'src/compositions/common'
import { Discount } from 'src/model/discount.model'
import { ReviewProjection } from 'src/model/review.model'
import { Amount, PaymentProvider, QueryParams } from 'src/model/common.model'
import { UserCompanyResponse } from 'src/api/dto/company'

const userApiKey: InjectionKey<ReturnType<typeof useUserApi_>> = Symbol('qeepl_userApi')

export function useUserApi_(store: Pinia) {
  const { self } = store.api

  async function createOrder<T extends CreateOrderRequest>(request: T): Promise<UserOrderProjection> {
    return self.post(`/user/orders?v=2`, request)
      .then(res => res.data)
  }

  async function updateOrder<T extends UpdateOrderRequest>(request: T): Promise<UserOrderProjection> {
    return self.put(`/user/orders?v=2`, request)
      .then(res => res.data)
  }

  async function editOrder(request: EditOrderRequest): Promise<UserOrderProjection> {
    return self.put('/user/orders/edit?v=2', request)
      .then(res => res.data)
  }

  async function cancelOrder(orderId: string, cancellationReason: string): Promise<UserOrderProjection> {
    return self.post(`/user/orders/${ orderId }/cancel`, { cancellationReason })
      .then(res => res.data)
  }

  async function getDiscount(name: string): Promise<Discount> {
    return self.post('/user/discount', { name })
      .then(res => res.data)
  }

  async function getOrders(): Promise<UserOrderProjection[]> {
    return self.get('/user/orders?v=2')
      .then(res => res.data)
  }

  async function payOrder(id: string): Promise<UserOrderProjection> {
    return self.get(`/user/orders/${ id }/pay?v=2`)
      .then(res => res.data)
  }

  async function getOrderCompany(id: string): Promise<UserCompanyResponse> {
    return self.get(`/user/orders/${ id }/company?v=2`)
      .then(res => res.data)
  }

  async function markOrderAccepted(orderId: string, photos: string[]): Promise<UserOrderProjection> {
    return self.post(`/user/orders/${ orderId }/mark-accepted?v=2`, { photos })
      .then(res => res.data)
  }

  async function markOrderReturned(orderId: string): Promise<UserOrderProjection> {
    return self.get(`/user/orders/${ orderId }/mark-returned?v=2`)
      .then(res => res.data)
  }

  async function getEditPrice(request: GetEditPriceRequest): Promise<Amount> {
    return self.post('/user/orders/edit-price?v=2', request)
      .then(res => res.data)
  }

  async function removePaymentMethod(orderId: string): Promise<UserOrderProjection> {
    return self.delete(`/user/orders/payment-method/${ orderId }`)
      .then(res => res.data)
  }

  async function initPayment(orderId: string, language?: Language, provider?: PaymentProvider): Promise<string> {
    const params = {
      language,
      provider
    }
    return self.get(`/user/payment/init/${ orderId }${ QueryParams.of(params) }`)
      .then(res => res.data)
  }

  async function cancelPayingPayment(orderId: string): Promise<void> {
    return self.get(`/user/payment/cancel-paying/${ orderId }`)
      .then(res => res.data)
      .catch(() => {
      })
  }

  async function completePayment(request: CompletePaymentRequest): Promise<boolean> {
    return self.post('/user/payment/complete?v=2', request)
      .then(() => true)
  }

  async function canReviewOrder(orderId: string): Promise<boolean> {
    return self.get(`/user/orders/${ orderId }/can-review`)
      .then(() => true)
      .catch(() => false)
  }

  async function getReview(orderId: string): Promise<ReviewProjection> {
    return self.get(`/user/orders/${ orderId }/get-review?`)
      .then(res => res.data)
  }

  async function reviewOrder(orderId: string, request: OrderReviewRequest): Promise<void> {
    return self.post(`/user/orders/${ orderId }/review`, request)
  }

  async function update(request: UpdateUserRequest): Promise<UserProjection> {
    return self.post('/user/update', request)
      .then(res => res.data)
  }

  async function updateLanguage(language: Language): Promise<void> {
    return self.get(`/user/update/language/${ language }`)
      .then(res => res.data)
      .catch(() => {
      })
  }

  async function saveFirebaseToken(token: string): Promise<void> {
    return self.post('/user/firebase/save-token', { token })
      .then(res => res.data)
      .catch(() => {
      })
  }

  async function saveVerificationReference(reference: string): Promise<void> {
    return self.post('/user/verification/save-reference', { data: reference })
      .then(res => res.data)
  }

  async function checkVerification(): Promise<boolean> {
    return self.get('/user/verification/check')
      .then(res => true)
      .catch(() => false)
  }

  return {
    createOrder,
    updateOrder,
    editOrder,
    cancelOrder,
    getDiscount,
    getOrders,
    payOrder,
    getOrderCompany,
    markOrderAccepted,
    markOrderReturned,
    getEditPrice,
    removePaymentMethod,
    initPayment,
    cancelPayingPayment,
    completePayment,
    canReviewOrder,
    reviewOrder,
    getReview,
    update,
    updateLanguage,
    saveFirebaseToken,
    saveVerificationReference,
    checkVerification,
  }
}

export default function useUserApi() {
  return injectSafe(userApiKey, () => useUserApi_(getActivePinia()!))
}
